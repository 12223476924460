import {Action, Card, DataStatus} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {filter, isNotNil, mergeAll} from 'ramda';
import {isNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  useGetSaleVehicleByVehicleQuery,
  useGetSaleVehicleQuery,
  useGetVehicleWarehouseManagementSettingsQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {Main, usePermissions, useVehicleWarehouse, useVehicleWarehouseContext} from '@dms/shared';

import {TestIdProps, buildArray, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {VehicleWarehouseInfoCard} from './components/VehicleWarehouseInfoCard';
import {VehicleWarehousePreviousEntities} from './components/VehicleWarehousePreviousEntities';

interface VehicleWarehouseInfoProps extends TestIdProps {
  vehicleId: string;
}

export function VehicleWarehouseInfo(props: VehicleWarehouseInfoProps) {
  const {
    data: saleVehicle,
    isLoading,
    isError,
  } = useGetSaleVehicleQuery({vehicleId: props.vehicleId});

  const {data: allSaleVehicles} = useGetSaleVehicleByVehicleQuery({
    vehicleId: props.vehicleId,
    type: 'IN_STOCK',
  });

  const {data: warehouseManagmentData} = useGetVehicleWarehouseManagementSettingsQuery();

  const hasWarehouseManagementEnabled = warehouseManagmentData?.isEnabled;

  const {dataGridRef, refreshDataGrid} = useVehicleWarehouseContext();

  const previousSaleVehicleIds = filter(
    (vehicle) => vehicle !== allSaleVehicles?.activeSaleVehicleId,
    allSaleVehicles?.saleVehicleIds ?? []
  );

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {saleVehicle: saleVehicle?.id}]);

  const {
    availableActions,
    openStockInDialog,
    openStockOutDialog,
    openCancelStockInDialog,
    openCancelStockOutDialog,
    openTransferDialog,
  } = useVehicleWarehouse({
    vehicleId: props.vehicleId,
    saleVehicleId: saleVehicle?.id,
    vehicleWarehouseId: saleVehicle?.vehicleWarehouse?.id,
    onMovementComplete: () => refreshDataGrid(),
    'data-testid': props['data-testid'],
  });

  const [
    canIssueFromVehicleWarehouse,
    canReceiveToVehicleWarehouse,
    canCancelReceivalWarehouse,
    canCancelIssuingWarehouse,
  ] = usePermissions({
    permissionKeys: [
      'vehicleIssueFromVehicleWarehouse',
      'vehicleReceiveToVehicleWarehouse',
      'vehicleCancelReceivalWarehouse',
      'vehicleCancelIssuingWarehouse',
    ],
  });

  const actions = buildArray<Action>()
    .when(availableActions.cancelReceipt && canCancelReceivalWarehouse, {
      title: i18n.t('entity.vehicleWarehouse.actions.warehouseCancelStockIn'),
      variant: 'outlined',
      type: 'button',
      leftIcon: 'navigation/cancel_outline',
      onClick: openCancelStockInDialog,
    })
    .when(availableActions.cancelIssue && canCancelIssuingWarehouse, {
      title: i18n.t('entity.vehicleWarehouse.actions.warehouseCancelStockOut'),
      variant: 'outlined',
      type: 'button',
      leftIcon: 'navigation/cancel_outline',
      onClick: openCancelStockOutDialog,
    })
    .when(
      availableActions.receive && canReceiveToVehicleWarehouse && hasWarehouseManagementEnabled,
      {
        title: i18n.t('entity.vehicleWarehouse.actions.warehouseStockIn'),
        variant: 'outlined',
        type: 'button',
        leftIcon: 'custom/warehouse',
        onClick: openStockInDialog,
      }
    )
    .when(availableActions.issue && canIssueFromVehicleWarehouse, {
      title: i18n.t('entity.vehicleWarehouse.actions.warehouseStockOut'),
      variant: 'outlined',
      type: 'button',
      leftIcon: 'content/reply',
      onClick: () => openStockOutDialog(),
    })
    .when(
      availableActions.transfer && canIssueFromVehicleWarehouse && canReceiveToVehicleWarehouse,
      {
        title: i18n.t('entity.vehicleWarehouse.actions.warehouseTransfer'),
        variant: 'outlined',
        type: 'button',
        leftIcon: 'content/redo',
        onClick: () => openTransferDialog(),
      }
    );

  return (
    <Main actions={actions}>
      <VStack spacing={8}>
        <VStack spacing={4}>
          <DataStatus
            isEmpty={isNilOrEmpty(saleVehicle?.id)}
            isLoading={isLoading}
            isError={isError}
            minHeight={100}
          >
            <Show when={isNotNil(saleVehicle) && isNotNil(saleVehicle?.id)}>
              {isNotNil(saleVehicle?.id) && (
                <>
                  <VehicleWarehouseInfoCard
                    vehicleId={props.vehicleId}
                    saleVehicleId={saleVehicle.id}
                    areWarehouseOperationsHidden
                  />
                  <Card title={i18n.t('entity.vehicle.labels.vehicleWarehouseOperations')}>
                    <DataGrid
                      gridCode="active_sale_vehicle_warehouse_movement"
                      queryModifier={queryModifier}
                      autoHeight
                      ref={dataGridRef}
                      data-testid={suffixTestId('datagrid-vehicleWarehouseIno', props)}
                    />
                  </Card>
                </>
              )}
            </Show>
          </DataStatus>
        </VStack>
        <Show when={isPositive(previousSaleVehicleIds.length)}>
          <VehicleWarehousePreviousEntities
            vehicleId={props.vehicleId}
            saleVehicleIds={previousSaleVehicleIds}
          />
        </Show>
      </VStack>
    </Main>
  );
}
