import {Button, DataStatus} from 'platform/components';
import {Box, Right, Space, Stack, VStack, Hide} from 'platform/foundation';

import {useLocation, useNavigate} from 'react-router-dom';

import {useGetVehicleQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';

import {suffixTestId, composePath, TestIdProps} from 'shared';

import {useGeneralVehicleParameters} from '../../hooks/useGeneralVehicleParameters';
import {queryParams} from '../../utils/queryParams';
import {VehicleInfoCard} from './components/VehicleInfoCard';
import {useEngineVehicleParameters} from './hooks/useEngineVehicleParameters';
import {useFuelVehicleParameters} from './hooks/useFuelVehicleParameters';
import {useServiceInspectionParameters} from './hooks/useServiceInspectionParameters';
import {useSpecificationVehicleParameters} from './hooks/useSpecificationVehicleParameters';
import {useTechnicalInspectionParameters} from './hooks/useTechnicalInspectionParameters';
import {useWarrantyAndValidityVehicleParameters} from './hooks/useWarrantyAndValidityVehicleParameters';

interface VehicleOverviewProps extends TestIdProps {
  vehicleId: string;
  isDetailButtonHidden?: boolean;
  isEditingDisabled?: boolean;
}

export function VehicleOverview(props: VehicleOverviewProps) {
  const navigate = useNavigate();
  const {pathname, search} = useLocation();

  const {isLoading: isVehicleLoading, isError: isVehicleError} = useGetVehicleQuery({
    vehicleId: props.vehicleId,
  });

  const generalParameters = useGeneralVehicleParameters(props.vehicleId);
  const specificationParameters = useSpecificationVehicleParameters(props.vehicleId);
  const fuelParameters = useFuelVehicleParameters(props.vehicleId);
  const engineParameters = useEngineVehicleParameters(props.vehicleId);
  const warrantyAndValidityParameters = useWarrantyAndValidityVehicleParameters(props.vehicleId);
  const technicalInspectionParameters = useTechnicalInspectionParameters(props.vehicleId);
  const serviceInspectionParameters = useServiceInspectionParameters(props.vehicleId);

  const handleNavigateWithHash = (hash: string) => {
    const searchParams = new URLSearchParams({[queryParams.RETURN_URL]: pathname + search});

    return navigate({
      hash,
      pathname: composePath(vehiclesRoutes.edit, {
        params: {
          id: props.vehicleId,
        },
        isSearchParamsPreserved: false,
      }),
      search: `?${searchParams.toString()}`,
    });
  };

  const handleNavigateVehicleData = (hash?: string) => {
    const searchParams = new URLSearchParams({[queryParams.RETURN_URL]: pathname + search});

    return navigate({
      hash,
      pathname: composePath(vehiclesRoutes.editDetailTab, {
        params: {
          id: props.vehicleId,
          tab: 'vehicle-data',
        },
        isSearchParamsPreserved: false,
      }),
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <DataStatus isLoading={isVehicleLoading} isError={isVehicleError} minHeight={100}>
      <Hide when={props.isDetailButtonHidden}>
        <Right>
          <Button
            variant="outlined"
            leftIcon="action/launch"
            title={i18n.t('entity.vehicle.labels.vehicleDetails')}
            data-testid={suffixTestId('vehicleDetails', props)}
            onClick={() =>
              window.open(
                composePath(vehiclesRoutes.detail, {params: {id: props.vehicleId}}),
                '_ blank'
              )
            }
          />
        </Right>
        <Space vertical={4} />
      </Hide>
      <Stack direction={['column', 'column', 'column', 'row']} spacing={4}>
        <Box flex={1}>
          <VStack spacing={4}>
            <VehicleInfoCard
              title={i18n.t('entity.vehicle.labels.generalInformation')}
              onEditClick={() => handleNavigateWithHash('info')}
              parameters={generalParameters}
              isEditingDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('general-info', props)}
            />
            <VehicleInfoCard
              title={i18n.t('entity.vehicle.labels.modelSpecification')}
              onEditClick={() => handleNavigateWithHash('model')}
              parameters={specificationParameters}
              isEditingDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('model-specification', props)}
            />
            <VehicleInfoCard
              title={i18n.t('entity.vehicle.labels.fuel')}
              onEditClick={() => handleNavigateWithHash('fuel')}
              parameters={fuelParameters}
              isEditingDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('fuel', props)}
            />
            <VehicleInfoCard
              title={i18n.t('entity.vehicle.labels.engine')}
              onEditClick={() => handleNavigateWithHash('engine')}
              parameters={engineParameters}
              isEditingDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('engine', props)}
            />
          </VStack>
        </Box>
        <Box flex={1}>
          <VStack spacing={4}>
            <VehicleInfoCard
              title={i18n.t('entity.vehicle.labels.warrantyAndValidity')}
              onEditClick={() => handleNavigateVehicleData('warranty')}
              parameters={warrantyAndValidityParameters}
              isEditingDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('warranty-and-validity', props)}
            />
            <VehicleInfoCard
              title={i18n.t('entity.vehicle.labels.technicalInspection')}
              onEditClick={() => handleNavigateVehicleData('technical-inspection')}
              parameters={technicalInspectionParameters}
              isEditingDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('technical-inspection', props)}
            />
            <VehicleInfoCard
              title={i18n.t('entity.vehicle.labels.serviceInspection')}
              onEditClick={() => handleNavigateVehicleData()}
              parameters={serviceInspectionParameters}
              isEditingDisabled={props.isEditingDisabled}
              data-testid={suffixTestId('service-inspections', props)}
            />
          </VStack>
        </Box>
      </Stack>
    </DataStatus>
  );
}
