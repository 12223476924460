import {
  Alert,
  Checkbox,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Heading, Hide, Show, Space, Text, VStack} from 'platform/foundation';

import {isNil} from 'ramda';
import {isFalsy, isTrue} from 'ramda-adjunct';

import {
  Channel,
  useGetNotificationDefinitionsQuery,
  useGetNotificationSettingsQuery,
  usePatchTenantNotificationSettingsMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate, useRequiredParams} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CustomFieldsNotificationSettings} from './components/CustomFieldsNotificationSettings';
import {FormValues} from './types/FormValues';
import {getDefaultValues} from './utils/getDefaultValues';
import {getSubmitValues} from './utils/getSubmitValues';

const breadcrumbs = [
  {
    label: i18n.t('page.settings.notifications.title'),
    href: settingsRoutes.notificationsOverview,
  },
];

export function NotificationSettingsDetail() {
  const navigate = useNavigate();
  const {id} = useRequiredParams();

  const {
    data: notificationSettings,
    isLoading: isLoadingNotificationSettings,
    isError: isNotificationSettingsError,
  } = useGetNotificationSettingsQuery({id});
  const {
    data: notification,
    isLoading: isLoadingNotification,
    isError: isNotificationError,
  } = useGetNotificationDefinitionsQuery({id});

  const [patchTenantSettings] = usePatchTenantNotificationSettingsMutation();

  const allowedChannels = (notification?.channels ?? []).reduce(
    (prev, current) => ({...prev, [current]: true}),
    {} as Partial<Record<Channel, boolean>>
  );
  const areAnyChannelsEditable =
    allowedChannels.sms || allowedChannels.email || allowedChannels.toastNotification;

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    if (isNil(notification)) {
      throw new Error('notification is undefined');
    }

    const patchTenantNotificationSettingsRequestBody = getSubmitValues(values, allowedChannels);

    await patchTenantSettings({id, patchTenantNotificationSettingsRequestBody})
      .unwrap()
      .then(() => {
        navigate(settingsRoutes.notificationsOverview);
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const isLoading = isLoadingNotificationSettings || isLoadingNotification;
  const isError = isNotificationSettingsError || isNotificationError;

  const defaultValues = getDefaultValues(notificationSettings);
  const canTenantEdit = notification?.obligation !== 'system';

  return (
    <SettingsTemplate
      header={{
        title: notification?.name,
        subtitle: notification?.description,
        breadcrumbs,
      }}
      data-testid={testIds.settings.notificationsDetail('page')}
      isError={isError}
      isLoading={isLoading}
    >
      <SettingsSection>
        <Form<FormValues> defaultValues={defaultValues} onSubmit={handleSubmit}>
          {(control, formApi) => {
            const channels = formApi.watch(['email', 'sms', 'toastNotification']);
            const isSettingForced = formApi.watch('force') ?? false;
            const areSomeChannelsEnabled = channels.some(isTrue);

            const handleSetAllChannels = (value: boolean) => {
              formApi.setValue('email', value);
              formApi.setValue('sms', value);
              formApi.setValue('toastNotification', value);
            };

            return (
              <VStack spacing={6}>
                <Hide when={canTenantEdit}>
                  <Alert
                    variant="warning"
                    message={i18n.t('page.settings.notifications.obligationWarningSystem')}
                  />
                </Hide>

                <Text size="small" color="secondary">
                  {i18n.t('page.settings.notifications.detailLabel')}
                </Text>

                <Checkbox
                  value={areSomeChannelsEnabled}
                  onChange={handleSetAllChannels}
                  label={i18n.t('page.settings.notifications.enabled')}
                  helperText={i18n.t('page.settings.notifications.forcedHelper')}
                  data-testid={testIds.settings.notificationsDetail('enabled')}
                  isDisabled={!canTenantEdit || formApi.formState.isSubmitting}
                />

                <VStack spacing={4}>
                  <FormField
                    control={control}
                    type="checkbox"
                    name="force"
                    label={i18n.t('page.settings.notifications.forced')}
                    data-testid={testIds.settings.notificationsDetail('forced')}
                    isDisabled={!canTenantEdit}
                  />

                  <Show when={isSettingForced}>
                    <Alert
                      variant="info"
                      message={i18n.t('page.settings.notifications.forcedMessage')}
                    />
                  </Show>
                </VStack>

                <Separator spacing={0} />

                <Heading size={4}>{i18n.t('page.settings.notifications.channels')}</Heading>
                <DataStatus isEmpty={!areAnyChannelsEditable}>
                  <Show when={isTrue(allowedChannels.sms)}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="sms"
                      label={i18n.t('page.settings.notifications.sendToSms')}
                      data-testid={testIds.settings.notificationsDetail('sendToSms')}
                      isDisabled={!canTenantEdit}
                    />
                  </Show>
                  <Show when={isTrue(allowedChannels.email)}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="email"
                      label={i18n.t('page.settings.notifications.sendToEmail')}
                      data-testid={testIds.settings.notificationsDetail('sendToEmail')}
                      isDisabled={!canTenantEdit}
                    />
                  </Show>

                  <Show when={isTrue(allowedChannels.toastNotification)}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="toastNotification"
                      label={i18n.t('page.settings.notifications.sendToApp')}
                      data-testid={testIds.settings.notificationsDetail('sendToApp')}
                      isDisabled={!canTenantEdit || isFalsy(allowedChannels.toastNotification)}
                    />
                  </Show>
                </DataStatus>

                <CustomFieldsNotificationSettings
                  obligation={notification?.obligation}
                  data-testid={testIds.settings.notificationsDetail('additionalSettings')}
                  notificationSettings={notificationSettings}
                  formApi={formApi}
                />

                <Space vertical={14} />

                <SettingsFooter
                  actions={[
                    {
                      type: 'button',
                      variant: 'secondary',
                      title: i18n.t('general.actions.discardChanges'),
                      onClick: () => navigate(settingsRoutes.notificationsOverview),
                      'data-testid': testIds.settings.notificationsDetail('discard'),
                    },
                    {
                      type: 'form-button',
                      control,
                      title: i18n.t('general.actions.saveChanges'),
                      buttonType: 'submit',
                      isDisabled: !canTenantEdit,
                      'data-testid': testIds.settings.notificationsDetail('save'),
                    },
                  ]}
                />
              </VStack>
            );
          }}
        </Form>
      </SettingsSection>
    </SettingsTemplate>
  );
}
