import {isFeatureEnabled} from 'feature-flags';

import {featureFlags} from '@dms/feature-flags';
import {testIds} from '@dms/routes';

import {DataGrid} from 'features/datagrid';

import {useVehiclesActionCallback} from '../../hooks/useVehiclesActionCallback';

export function VehiclesOverview() {
  const [actionCallback] = useVehiclesActionCallback();

  return (
    <DataGrid
      gridCode={
        isFeatureEnabled(featureFlags.REPLACE_VEHICLE_DG_WITH_OPENSEARCH)
          ? 'vehicle-opensearch'
          : 'vehicle'
      }
      // eslint-disable-next-line no-restricted-syntax
      key="vehicle"
      data-testid={testIds.vehicles.home()}
      actionCallback={actionCallback}
    />
  );
}
