import {
  Actions,
  Button,
  DataStatus,
  DialogsContext,
  Parameters,
  useAnimatedPopper,
  openDialog,
} from 'platform/components';
import {Box, HStack, Heading, Space, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useContext} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetServiceCaseIntegrationsQuery,
  useLazyGetServiceCaseIntegrationsXentryOrderSynchronizationApiQuery,
} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, parseDate, suffixTestId} from 'shared';

import {MERCEDES_XENTRY_SYNC} from '../pages/ServiceCaseDetail/constants/mercedesXentrySync';
import {MERCEDES_XENTRY_SYNC_ACTION} from '../pages/ServiceCaseDetail/constants/mercedesXentrySyncAction';
import {SyncXentry} from './SyncXentry/SyncXentry';

interface IntegrationsDropdownProps extends TestIdProps {
  serviceCaseId: string;
}

export function IntegrationsDropdown(props: IntegrationsDropdownProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data, isError, isLoading, refetch} = useGetServiceCaseIntegrationsQuery(
    {serviceCaseId: props.serviceCaseId},
    {skip: !props.serviceCaseId}
  );

  const [getXentrySync, {isLoading: isLoadingXentrySync}] =
    useLazyGetServiceCaseIntegrationsXentryOrderSynchronizationApiQuery();

  const linkedXentryIntegration = data?.caseIntegration?.find(
    (caseIntegration) => caseIntegration?.integration?.featureCode === MERCEDES_XENTRY_SYNC
  );

  const xentryIntegrationAction = linkedXentryIntegration?.action?.find(
    (action) => action?.key === MERCEDES_XENTRY_SYNC_ACTION
  );

  const {isAnyDialogOpen} = useContext(DialogsContext);

  const {popperProps, Popper, togglePopper, referenceRef} = useAnimatedPopper({
    placement: 'bottom-end',
    gutter: 4,
    isOutsideClickIgnored: isAnyDialogOpen,
  });

  const onSyncClick = async () => {
    const {data} = await getXentrySync({
      serviceCaseId: props.serviceCaseId,
    });

    if (!data) {
      refetch();
      return;
    }

    openDialog(
      <SyncXentry
        serviceCaseId={props.serviceCaseId}
        data-testid={suffixTestId('syncXentry', props)}
      />,
      {
        title: i18n.t('entity.integration.labels.xentrySync'),
        id: 'selectXentryDialog',
        size: 'large',
      }
    );
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Box ref={referenceRef}>
        <Button
          title={i18n.t('entity.orderIntegration.labels.integration')}
          variant="secondary"
          isDisabled={xentryIntegrationAction?.style !== 'enable'}
          data-testid={props['data-testid']}
          onClick={(event) => {
            event.stopPropagation();
            togglePopper();
          }}
        />
      </Box>
      <Popper {...popperProps}>
        <Box
          borderRadius="medium"
          boxShadow="elevation_2"
          border="1px solid"
          borderColor="palettes.neutral.40.100"
          backgroundColor="general.white"
          padding={3}
        >
          <Box padding={2}>
            <VStack spacing={4}>
              <Box
                key={linkedXentryIntegration?.integration?.code}
                padding={4}
                border="1px solid"
                borderColor="general.separator"
                borderRadius="small"
                backgroundColor={
                  !linkedXentryIntegration?.integration?.createdAt
                    ? 'palettes.neutral.10.100'
                    : undefined
                }
              >
                <HStack spacing={4} align="center" justify="space-between" minWidth={100}>
                  <Heading size={4}>{i18n.t('entity.orderIntegration.labels.xentryOrder')}</Heading>
                  <Actions
                    size="small"
                    actions={[
                      {
                        type: 'button',
                        title: i18n.t('entity.orderIntegration.actions.sync'),
                        leftIcon: 'action/update',
                        variant: 'outlined',
                        isLoading: isLoadingXentrySync,
                        onClick: onSyncClick,
                      },
                    ]}
                    data-testid={suffixTestId('actions', props)}
                  />
                </HStack>
                <Space vertical={3} />
                <HStack align="center">
                  <Parameters
                    color="secondary"
                    size="xSmall"
                    parameters={[
                      i18n.t('entity.orderIntegration.labels.lastSync', {
                        sync: isNotNilOrEmpty(linkedXentryIntegration?.integration?.updatedAt)
                          ? formatDateTime(
                              'dateTimeShort',
                              parseDate(linkedXentryIntegration?.integration?.updatedAt)
                            )
                          : i18n.t('entity.orderIntegration.labels.noSync'),
                      }),
                    ]}
                    data-testid={suffixTestId('parameters', props)}
                  />
                </HStack>
              </Box>
            </VStack>
          </Box>
        </Box>
      </Popper>
    </DataStatus>
  );
}
