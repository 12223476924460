import {parseISO} from 'date-fns';
import {isFeatureEnabled} from 'feature-flags';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  CorrectiveTaxDocumentV2RequestBody,
  useGetDocumentSeriesDefinitionListQuery,
  useGetTenantQuery,
  useInvoicingDocumentRecalculateMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {useQueryState, useRequiredParams} from 'shared';

import {ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY, PERCENTAGE_SIGN} from '../../../constants';
import {AccountingDocumentFormValues, InvoiceItem} from '../../../types';
import {getInvoicingDocumentType} from '../utils/getInvoicingDocumentType';

export const useGetCorrectiveTaxDocumentBody = () => {
  const [invoiceType] = useQueryState('invoiceType');
  const formatDateTime = useDateTimeFormatter();
  const {id} = useRequiredParams();
  const {data: tenant} = useGetTenantQuery();
  const currency = tenant?.currency as string;

  const invoicingDocumentType = getInvoicingDocumentType(invoiceType);
  const [_, {data: summary}] = useInvoicingDocumentRecalculateMutation({
    fixedCacheKey: ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  });

  const {data: documentSeries} = useGetDocumentSeriesDefinitionListQuery();

  const getRequestBody = (
    formData: AccountingDocumentFormValues
  ): CorrectiveTaxDocumentV2RequestBody => {
    const documentSeriesId =
      documentSeries?.find((item) => item.id === formData.documentSeriesId)?.documentSeriesId ??
      formData.documentSeriesId;

    const items = formData.invoiceItems.map((item: InvoiceItem) => {
      const discount =
        summary?.items.find(
          (summaryItem) => summaryItem.itemId === item.itemId && summaryItem.type === 'discount'
        )?.discount ?? null;

      return {
        currency,
        description: item.description,
        isUnitPriceWithVat: item.isUnitPriceWithVat,
        itemId: item.itemId,
        pricePerUnit: item.pricePerUnit?.toString() ?? '0',
        quantity: item.unit === PERCENTAGE_SIGN ? '1' : (item.quantity?.toString() ?? '0'),
        type: item.type,
        unit: item.unit,
        correctedItemId: item.itemId,
        vatType: item.vatType,
        discount: item.type === 'discount' ? discount : null,
        relatedItemId: item.relatedItemId,
      };
    });

    return {
      dateOfReception: formData.dateOfReception ?? '',
      behindItemsNote: formData.footerNote ?? null,
      customFields: isNilOrEmpty(formData.customFieldsPayload)
        ? undefined
        : formData.customFieldsPayload,
      dateOfTaxableSupply: formData.dateOfTaxableSupply,
      description: i18n.t('entity.correctiveTaxDocument.labels.descriptionContent', {
        invoiceNumber: formData.number,
        issueDate: formatDateTime('dateShort', parseISO(formData.issueDate)),
      }),
      documentSeriesId,
      dueDate: formData.dueDate,
      internalNote: formData.internalNote ?? null,
      issueDate: formData.issueDate,
      paymentInfo: formData.paymentInfo,
      additionalCustomer:
        isNotNil(formData.additionalCustomer) &&
        isFeatureEnabled(featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER) &&
        isNotNil(formData.additionalCustomer.contractInformationId)
          ? {
              contractInformationId: formData.additionalCustomer.contractInformationId,
              customerId: formData.additionalCustomer.customer.id,
              type: formData.additionalCustomer.type,
            }
          : null,
      reason: formData.reason ?? '',
      invoicingDocumentType,
      invoicingDocumentId: id,
      items,
      invoiceCategory: formData.isInternal ? 'internal' : null,
      exchangeRateRatio: formData.isExchangeRateRatioEnabled
        ? {
            amount: parseFloat(formData.exchangeRateRatio?.amount ?? ''),
            currency: formData.exchangeRateRatio?.currency ?? '',
            ratio: formData.exchangeRateRatio?.ratio?.toString() ?? '',
          }
        : null,
    };
  };

  return [getRequestBody];
};
