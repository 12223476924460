import {closeDialog, openDeleteDialog, openDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  LabeledKey,
  useDeleteServiceOrderItemsMutation,
  usePostServiceItemsMaterialPartIssuedMutation,
  usePostServiceItemsMaterialPartRequestMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {EditMaterialItem, handleApiError, catchUnhandledDataGridActions} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {MATERIAL_TYPE} from '../../../constants/materialType';
import {BulkAssignMechanicForm} from '../../BulkAssignMechanicForm';
import {BulkDiscountForm} from '../../BulkDiscountForm';
import {EditItem} from '../../EditItem/EditItem';
import {GoodwillForm} from '../../Goodwill/GoodwillForm';
import {MoveRequestItem} from '../../MoveRequestItem/MoveRequestItem';
import {WorkSplittingForm} from '../../WorkSplitting/WorkSplittingForm';

type ReceiveJobItemRowData = {
  itemName: {value: string};
  itemType: {value: LabeledKey};
  itemPriceType: {value: LabeledKey};
  itemGoodwillRatio: {value: {label: string} | Nullish};
  serviceOrderJobItemId: {value: string};
};

interface JobItemsDataGridProps extends TestIdProps {
  serviceCaseId: string;
  orderId: string | Nullish;
  jobId: string | Nullish;
  datagridKey: string | Nullish;
  requestName: string | Nullish;
}

export function JobItemsDataGrid(props: JobItemsDataGridProps) {
  const [deleteServiceOrderItems] = useDeleteServiceOrderItemsMutation();
  const [postServiceItemsMaterialPartRequest] = usePostServiceItemsMaterialPartRequestMutation();
  const [postServiceItemsMaterialPartIssued] = usePostServiceItemsMaterialPartIssuedMutation();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      serviceJobId: props.jobId ?? '',
      serviceOrderId: props.orderId ?? '',
      serviceCaseId: props.serviceCaseId,
    }),
    [props.jobId, props.orderId, props.serviceCaseId]
  );

  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData, deselectAll}) => {
    const castedRowData = rowData as ReceiveJobItemRowData | ReceiveJobItemRowData[];

    const editId = isArray(castedRowData)
      ? castedRowData[0].serviceOrderJobItemId.value
      : castedRowData.serviceOrderJobItemId.value;

    const items = isArray(castedRowData)
      ? castedRowData.map((selectedRow) => ({
          serviceItemId: selectedRow.serviceOrderJobItemId.value,
          serviceJobId: props.jobId || '',
        }))
      : [
          {
            serviceItemId: castedRowData.serviceOrderJobItemId.value,
            serviceJobId: props.jobId || '',
          },
        ];

    const itemName = isArray(castedRowData) ? undefined : castedRowData.itemName.value;
    const itemType = isArray(castedRowData) ? undefined : castedRowData.itemType.value;
    const itemPriceType = isArray(castedRowData) ? undefined : castedRowData.itemPriceType.value;

    const hasSomeGoodwill =
      isArray(castedRowData) &&
      castedRowData.some(
        (data) =>
          isNotNilOrEmpty(data.itemGoodwillRatio.value) &&
          Number(data.itemGoodwillRatio.value?.label) !== 100
      );

    match(actionKey)
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServiceOrderItems({
              serviceOrderId: props.orderId ?? '',
              serviceCaseId: props.serviceCaseId,
              body: {items},
            })
              .unwrap()
              .then(() => {
                deselectAll();
                refreshData();
              })
              .catch(handleApiError),
        })
      )
      .with('edit', 'redirectDetail', () =>
        openDialog(
          itemType?.key === MATERIAL_TYPE ? (
            <EditMaterialItem
              itemId={editId}
              onClose={() => closeDialog('editJobItem')}
              serviceCaseId={props.serviceCaseId}
              serviceOrderId={props.orderId ?? ''}
              serviceJobId={props.jobId ?? ''}
              onEdited={refreshData}
              data-testid={suffixTestId('editJobItem', props)}
            />
          ) : (
            <EditItem
              itemId={editId}
              onClose={() => closeDialog('editJobItem')}
              serviceCaseId={props.serviceCaseId}
              serviceOrderId={props.orderId ?? ''}
              serviceJobId={props.jobId ?? ''}
              onEdited={refreshData}
              data-testid={suffixTestId('editJobItem', props)}
            />
          ),
          {
            title: `${itemName} (${itemPriceType?.label})`,
            id: 'editJobItem',
          }
        )
      )
      .with('move', () =>
        openDialog(
          <MoveRequestItem
            onClose={() => closeDialog('moveRequestItem')}
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={String(props.orderId)}
            hiddenServiceJob={props.jobId}
            items={items}
            onMoveItems={refreshData}
            data-testid={suffixTestId('moveItem', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.moveRequestItem'),
            id: 'moveRequestItem',
          }
        )
      )
      .with('goodwill', () =>
        openDialog(
          <GoodwillForm
            onClose={() => closeDialog('goodwill')}
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={String(props.orderId)}
            requestName={props.requestName}
            items={items}
            onSaved={refreshData}
            isWithMultipleGoodwill={hasSomeGoodwill}
            data-testid={suffixTestId('goodwillDialog', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.goodwill'),
            id: 'goodwill',
          }
        )
      )
      .with('workSplitting', () =>
        openDialog(
          <WorkSplittingForm
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('workSplitting')}
            data-testid={suffixTestId('workSplitting', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.workSplitting'),
            id: 'workSplitting',
          }
        )
      )
      .with('request', () =>
        postServiceItemsMaterialPartRequest({
          body: {serviceItems: items},
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.orderId ?? '',
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('issue', () =>
        postServiceItemsMaterialPartIssued({
          body: {serviceItems: items},
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.orderId ?? '',
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('assignMechanic', () =>
        openDialog(
          <BulkAssignMechanicForm
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('assignMechanic')}
            data-testid={suffixTestId('assignMechanic', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.assignMechanic'),
            id: 'assignMechanic',
          }
        )
      )
      .with('discount', () =>
        openDialog(
          <BulkDiscountForm
            serviceCaseId={props.serviceCaseId ?? ''}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('manualDiscount')}
            data-testid={suffixTestId('manualDiscount', props)}
          />,
          {
            title: i18n.t('entity.orderItem.labels.manualDiscount'),
            id: 'manualDiscount',
            size: 'small',
          }
        )
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <DataGrid
      autoHeight
      gridCode="service-case-order-job-items"
      queryModifier={queryModifier}
      actionCallback={actionCallback}
      data-testid={suffixTestId('dataGrid', props)}
    />
  );
}
