import {array, mixed, number, object, string} from 'yup';

import i18n from '@dms/i18n';

import {POSITIVE_NUMBER_REGEX, yupString} from 'shared';

// TODO: https://carvago.atlassian.net/browse/T20-30755
export const $CreateIncomeCashReceiptSchema = object({
  issueDate: string().required(),
  reason: yupString,
  footerNote: yupString,
  cashRegisterId: yupString.required(),
  paymentInfo: object({
    paymentMethod: mixed().oneOf(['CARD', 'CASH']).required(),
    variableSymbol: yupString.max(10),
    paymentType: yupString.required(),
  }),
  invoiceItems: array().of(
    object({
      pricePerUnit: string().nullable().required(),
      quantity: string()
        .matches(POSITIVE_NUMBER_REGEX, i18n.t('general.notifications.numberPositive'))
        .nullable()
        .required(),
      vatType: mixed().oneOf([['S', 'R', 'MR', 'E', 'Z', 'SR', 'P']]),
      description: yupString.required(),
    })
  ),
  exchangeRateRatio: mixed().when('isExchangeRateRatioEnabled', {
    is: true,
    then: object({
      amount: string()
        .oneOf(['1', '100', '1000'], i18n.t('general.notifications.ratioAmountError'))
        .nullable(),
      ratio: number().positive().nullable().required(),
    }),
    otherwise: mixed().nullable(),
  }),
});
