import {AnimatePresence, motion, MotionStyle} from 'framer-motion';
import {Box, Heading, Hide, HStack, Show, Space, ThemeZIndexPath} from 'platform/foundation';
import styled, {useTheme} from 'styled-components';

import {MouseEvent, ReactNode, useEffect} from 'react';

import {isNotNil, not} from 'ramda';

import {RequiredTestIdProps, suffixTestId, TestIdProps} from 'shared';

import {ButtonProps} from '../Button/Button';
import {ButtonGroup} from '../ButtonGroup/ButtonGroup';
import {IconButton} from '../IconButton/IconButton';
import {Pagination, PaginationProps} from '../Pagination/Pagination';
import {Portal} from '../Portal/components/Portal';
import {ModalTransition} from './components/DialogTransition';
import {DialogSize} from './types/DialogSize';
import {ScrollBehavior} from './types/ScrollBehavior';

const DEFAULT_SCROLL_BEHAVIOR = 'inside';

type DialogPaginationProps = Pick<PaginationProps, 'pagesQuantity' | 'onPageChange' | 'page'>;

export interface DialogProps extends TestIdProps {
  isOpen: boolean;
  children: ReactNode;
  title?: string;
  scrollBehavior?: ScrollBehavior;
  size?: DialogSize;
  withAdditionalFooter?: boolean;
  id?: string;
  disableBodyPadding?: boolean;
  hideCloseButton?: boolean;
  /**
   * @about Displays sticky footer with buttons inside
   */
  buttons?: ButtonProps[];
  onClose?: () => void;
  onCloseComplete?: () => void;
  /**
   * If `true`, the modal will return focus to the element that triggered it when it closes.
   * @default true
   */
  returnFocusOnClose?: boolean;
  pagination?: DialogPaginationProps;
  /**
   * @about overrides z-index of the dialog so that it can be displayed above the lightbox
   */
  isInLightbox?: boolean;
}

const DIALOG_HEIGHT_BREAKPOINT_PX = 1000;
export function Dialog(props: DialogProps) {
  const isEnlarged = window.innerHeight <= DIALOG_HEIGHT_BREAKPOINT_PX;

  const handleCloseButtonClick = () => {
    props.onClose?.();
  };

  const handleOnOverlayClick = (event: MouseEvent) => {
    event.stopPropagation();

    props.onClose?.();
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onKeyDown = (event: Event) => {
    if ('key' in event && event.key === 'Escape') {
      event.stopPropagation();

      props.onClose?.();
    }
  };

  const scrollBehavior = props.scrollBehavior ?? DEFAULT_SCROLL_BEHAVIOR;

  return (
    <AnimatePresence onExitComplete={props.onCloseComplete}>
      {props.isOpen && (
        <Portal>
          <StyledOverlay
            zIndex={props.isInLightbox ? 'LIGHTBOX_MODAL' : 'MODAL'}
            data-testid={suffixTestId('dialogOverlay', props)}
            onClick={handleOnOverlayClick}
          >
            <StyledContentContainer
              role="dialog"
              data-testid={suffixTestId('dialogContent', props)}
              $scrollBehavior={scrollBehavior}
              $isEnlarged={isEnlarged}
              $zIndex={props.isInLightbox ? 'LIGHTBOX_MODAL' : 'MODAL'}
            >
              <ModalTransition scrollBehavior={scrollBehavior} size={props.size}>
                <Show when={props.title}>
                  <StyledHeader data-testid={suffixTestId('dialogHeader', props)}>
                    <HStack spacing={4} justify="space-between" align="center">
                      <Heading size={4}>{props.title}</Heading>
                      <HStack>
                        <Show when={isNotNil(props.pagination)}>
                          <Pagination
                            {...(props.pagination as DialogPaginationProps)}
                            variant="text-only"
                            showPrevButton
                            showNextButton
                            data-testid={suffixTestId('dialogHeaderPagination', props)}
                          />
                        </Show>
                        <Space horizontal={2} />
                        <Hide when={props.hideCloseButton}>
                          <IconButton
                            data-testid={suffixTestId('dialogCloseButton', props)}
                            icon="navigation/close"
                            onClick={handleCloseButtonClick}
                          />
                        </Hide>
                      </HStack>
                    </HStack>
                  </StyledHeader>
                </Show>

                <StyledBody
                  data-testid={suffixTestId('dialogBody', props)}
                  $withAdditionalFooter={props.withAdditionalFooter}
                  $disableBodyPadding={props.disableBodyPadding}
                  $scrollBehavior={scrollBehavior}
                >
                  {props.children}
                </StyledBody>

                <Show when={props.buttons && not(props.withAdditionalFooter)}>
                  <StyledButtonFooter data-testid={suffixTestId('dialogFooter', props)}>
                    <Box padding={4}>
                      <ButtonGroup buttons={props.buttons} />
                    </Box>
                  </StyledButtonFooter>
                </Show>
              </ModalTransition>
            </StyledContentContainer>
          </StyledOverlay>
        </Portal>
      )}
    </AnimatePresence>
  );
}

const StyledButtonFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
`;

interface StylesOverlayProps extends RequiredTestIdProps {
  zIndex: ThemeZIndexPath;
  onClick: (event: MouseEvent) => void;
  children: ReactNode;
}

const StyledOverlay = (props: StylesOverlayProps) => {
  const theme = useTheme();

  return (
    <motion.div
      onClick={props.onClick}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: theme.zIndices[props.zIndex] as MotionStyle['zIndex'],
        backgroundColor: theme.colors.palettes.neutral[900][20],
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      data-testid={props['data-testid']}
      variants={{
        visible: {
          opacity: 1,
          transition: {
            duration: 0.3,
            ease: [0.3, 0.1, 0.75, 0.9],
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            duration: 0.1,
            ease: [0.9, 0.75, 0.1, 0.3],
          },
        },
      }}
    >
      {props.children}
    </motion.div>
  );
};

type StyledBodyProps = {
  $withAdditionalFooter: DialogProps['withAdditionalFooter'];
  $disableBodyPadding: DialogProps['disableBodyPadding'];
  $scrollBehavior: DialogProps['scrollBehavior'];
};

const StyledHeader = styled.div`
  display: block;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid;
  border-color: ${({theme}) => theme.colors.general.separator};
`;

const StyledBody = styled.div<StyledBodyProps>`
  display: block;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: ${({$withAdditionalFooter, theme}) =>
    $withAdditionalFooter ? theme.getSize(16) : 0};
  padding: ${({$disableBodyPadding, theme}) => ($disableBodyPadding ? 0 : theme.getSize(4))};
  overflow: ${({$scrollBehavior}) => ($scrollBehavior === 'inside' ? 'auto' : undefined)};
`;

type StyledContentContainerProps = {
  $scrollBehavior: DialogProps['scrollBehavior'];
  $isEnlarged: boolean;
  $zIndex: ThemeZIndexPath;
};

const StyledContentContainer = styled.div<StyledContentContainerProps>`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({theme, $zIndex}) => theme.zIndices[$zIndex]};
  overflow: ${({$scrollBehavior}) => ($scrollBehavior === 'inside' ? 'hidden' : 'auto')};
  max-height: ${({$isEnlarged}) =>
    $isEnlarged ? `calc(100% - ${$isEnlarged ? 2 : 7.5}rem)` : undefined};
`;
