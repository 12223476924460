import {addDays, format, parseISO, startOfToday} from 'date-fns';
import {Card, DataStatus, Form, FormSubmitHandler} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';
import {DeepPartial} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {negate} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {useGetDocumentData} from '@dms/shared';

import {useQueryState} from 'shared';

import {CustomerAndSupplier} from '../../components/CustomerAndSupplier';
import {DocumentSeriesSelect} from '../../components/DocumentSeriesSelect';
import {FormHeader} from '../../components/FormHeader';
import {InvoicePage} from '../../components/InvoicePage';
import {ListOfItemsForm} from '../../components/listOfItems/ListOfItemsForm';
import {PaymentDates} from '../../components/PaymentDates';
import {PaymentDetails} from '../../components/PaymentDetails';
import {PaymentsNotes} from '../../components/PaymentsNotes';
import {PERCENTAGE_SIGN} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {AccountingDocumentFormValues} from '../../types';
import {$CreateCorrectiveTaxDocumentSchema} from './$CreateCorrectiveTaxDocumentSchema';
import {CorrectiveTaxDocumentDetails} from './CorrectiveTaxDocumentDetails';
import {useHandleSubmitCheckoutCorrectiveDocument} from './hooks/useHandleSubmitCheckoutCorrectiveDocument';
import {useHandleSubmitCorrectiveTaxDocument} from './hooks/useHandleSubmitCorrectiveTaxDocument';

export function CreateCorrectiveTaxDocument() {
  const {id} = useParams();
  const [invoiceType] = useQueryState('invoiceType');
  const [checkoutId] = useQueryState('checkoutId');

  const isFromCheckout = isNotNil(checkoutId);

  const [createCorrectiveTaxDocument] = useHandleSubmitCorrectiveTaxDocument();
  const [createCheckoutCorrectiveTaxDocument] = useHandleSubmitCheckoutCorrectiveDocument();
  const {data: accountingDocument, isError, isLoading} = useGetDocumentData(invoiceType, id);

  const handleFormSubmit: FormSubmitHandler<AccountingDocumentFormValues> = async (formData) => {
    const submitAction = isFromCheckout
      ? createCheckoutCorrectiveTaxDocument
      : createCorrectiveTaxDocument;

    await submitAction(formData);
  };

  const todaysDate = format(startOfToday(), 'yyyy-MM-dd');
  const defaultValues: DeepPartial<AccountingDocumentFormValues> = {
    additionalCustomer: isNotNil(accountingDocument?.additionalCustomer?.customer.id)
      ? {
          ...accountingDocument?.additionalCustomer,
          customer: {
            ...accountingDocument?.additionalCustomer?.customer,
            id: accountingDocument?.additionalCustomer?.customer.id,
          },
        }
      : null,
    currency: accountingDocument?.currency,
    customer: accountingDocument?.customer,
    customFieldsPayload: accountingDocument?.customFields,
    issueDate: todaysDate,
    dueDate: format(addDays(todaysDate, 14), 'yyyy-MM-dd'),
    dateOfTaxableSupply: todaysDate,
    dateOfReception: accountingDocument?.dateOfReception,
    internalNote: accountingDocument?.internalNote,
    paymentInfo: accountingDocument?.paymentInfo,
    id: accountingDocument?.id,
    supplier: accountingDocument?.supplier,
    invoiceItems: (accountingDocument?.items ?? [])
      .filter((item) => item.generatedItemType !== 'proforma_deduction')
      .map((item) => ({
        description: item.description,
        isUnitPriceWithVat: item.isUnitPriceWithVat,
        itemId: item.itemId,
        type: item.type,
        vatType: item.vatType,
        quantity:
          item.type === 'discount' && item.unit === PERCENTAGE_SIGN
            ? item.discount?.percent
            : item.quantity,
        pricePerUnit: negateStringValue(item.pricePerUnit.amount),
        relatedItemId: item.relatedItemId,
        unit: item.unit,
        priceWithoutVat: negateStringValue(item.totalPrice.base.amount),
        priceWithVat: negateStringValue(item.totalPrice.total.amount),
      })),
    documentSeriesId: accountingDocument?.documentSeriesId,
    exchangeRateRatio: accountingDocument?.exchangeRateRatio,
    number: accountingDocument?.number,
    reason: null,
    isExchangeRateRatioEnabled: accountingDocument?.isExchangeRateRatioEnabled,
    dueSinceIssueDateInDays: accountingDocument?.dueSinceIssueDateInDays,
    footerNote: accountingDocument?.footerNote,
    isInternal: accountingDocument?.invoiceCategory === 'internal',
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight="80vh">
      <Helmet title={i18n.t('entity.correctiveTaxDocument.labels.newDocument')} />
      <Form<AccountingDocumentFormValues>
        defaultValues={defaultValues}
        onSubmit={handleFormSubmit}
        schema={$CreateCorrectiveTaxDocumentSchema}
      >
        {(control, formApi) => (
          <InvoiceVatRateProvider
            control={control}
            listItemType="invoicing-documents"
            date={
              accountingDocument?.dateOfTaxableSupply
                ? format(parseISO(accountingDocument?.dateOfTaxableSupply), 'yyyy-MM-dd')
                : undefined
            }
          >
            <VStack>
              <FormHeader
                actions={[
                  {
                    type: 'form-button',
                    title: i18n.t('general.actions.create'),
                    control,
                    buttonType: 'submit',
                    'data-testid':
                      testIds.accounting.newCorrectiveTaxDocument('create-corrective-tax'),
                  },
                ]}
                title={i18n.t('entity.correctiveTaxDocument.labels.newDocument')}
              />
              <Hide when={isFromCheckout}>
                <DocumentSeriesSelect
                  control={control}
                  documentType="corrective_tax_document"
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('create-corrective-tax')}
                />
              </Hide>

              <InvoicePage>
                <Card>
                  <VStack spacing={4}>
                    <Show when={isNotNil(defaultValues)}>
                      <CustomerAndSupplier
                        control={control}
                        formApi={formApi}
                        supplier={accountingDocument?.supplier}
                        allowAdditionalCustomer
                        isCustomerFixed
                        data-testid={testIds.accounting.newCorrectiveTaxDocument(
                          'create-customer-and-supplier'
                        )}
                      />
                    </Show>
                    <PaymentDates
                      formApi={formApi}
                      control={control}
                      data-testid={testIds.accounting.newCorrectiveTaxDocument('payment-dates')}
                    />
                  </VStack>
                </Card>

                <PaymentDetails
                  control={control}
                  formApi={formApi}
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('payment-details')}
                  isCorrectiveTaxDocument
                />

                <CorrectiveTaxDocumentDetails
                  control={control}
                  formApi={formApi}
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('document-details')}
                />

                <ListOfItemsForm
                  control={control}
                  formApi={formApi}
                  listItemType="invoices"
                  isCorrectiveTaxDocument
                  dateOfTaxableSupply={accountingDocument?.dateOfTaxableSupply}
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('list-of-items')}
                />
                <PaymentsNotes
                  control={control}
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('payments-notes')}
                />
              </InvoicePage>
            </VStack>
          </InvoiceVatRateProvider>
        )}
      </Form>
    </DataStatus>
  );
}

const negateStringValue = (val: string) => negate(parseFloat(val))?.toString() ?? '';
