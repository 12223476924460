import {
  Alert,
  Checkbox,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Heading, Show, Text, VStack, Box, Space} from 'platform/foundation';

import {isNil} from 'ramda';
import {isFalsy, isTrue} from 'ramda-adjunct';

import {
  Channel,
  useGetNotificationDefinitionsQuery,
  useGetNotificationSettingsQuery,
  usePatchUserNotificationSettingsMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {myProfileRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate, useRequiredParams} from 'shared';

import {MyProfileFooter} from '../../components/MyProfileFooter';
import {MyProfileTemplate} from '../../components/MyProfileTemplate/MyProfileTemplate';
import {CustomFieldsNotificationSettings} from './components/CustomFieldsNotificationSettings';
import {FormValues} from './types/FormValues';
import {getDefaultValues} from './utils/getDefaultValues';
import {getSubmitValues} from './utils/getSubmitValues';

export function NotificationDetail() {
  const navigate = useNavigate();
  const {id} = useRequiredParams();

  const {
    data: notificationSettings,
    isLoading: isLoadingNotificationSettings,
    isError: isNotificationSettingsError,
  } = useGetNotificationSettingsQuery({id});
  const {
    data: notification,
    isLoading: isLoadingNotification,
    isError: isNotificationError,
  } = useGetNotificationDefinitionsQuery({id});

  const [patchTenantSettings] = usePatchUserNotificationSettingsMutation();

  const allowedChannels = (notification?.channels ?? []).reduce(
    (prev, current) => ({...prev, [current]: true}),
    {} as Partial<Record<Channel, boolean>>
  );
  const areAnyChannelsEditable =
    allowedChannels.sms || allowedChannels.email || allowedChannels.toastNotification;

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    if (isNil(notification)) {
      throw new Error('notification is undefined');
    }

    const patchUserNotificationSettingsRequestBody = getSubmitValues(values, allowedChannels);

    await patchTenantSettings({id, patchUserNotificationSettingsRequestBody})
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(() => navigate(myProfileRoutes.notifications))
      .catch(handleApiError);
  };

  const isLoading = isLoadingNotificationSettings || isLoadingNotification;
  const isError = isNotificationSettingsError || isNotificationError;

  const defaultValues = getDefaultValues(notificationSettings);
  const isSettingsForced = isTrue(notificationSettings?.tenantSettingsForced);
  const canUserEdit = notification?.obligation === 'user';

  const isFormReadOnly = isSettingsForced || !canUserEdit;

  const breadcrumbs = [
    {label: i18n.t('entity.user.labels.myProfile'), isCurrentPage: true},
    {
      label: i18n.t('page.settings.notifications.title'),
      href: myProfileRoutes.notifications,
    },
    {label: notification?.name ?? '', isCurrentPage: true},
  ];

  return (
    <MyProfileTemplate
      header={{
        title: notification?.name ?? '',
        subtitle: notification?.description,
        breadcrumbs,
      }}
      data-testid={testIds.myProfile.notificationDetail('page')}
      isError={isError}
      isLoading={isLoading}
    >
      <Box maxWidth={200}>
        <Form<FormValues> defaultValues={defaultValues} onSubmit={handleSubmit}>
          {(control, formApi) => {
            const channels = formApi.watch(['email', 'sms', 'toastNotification']);
            const areSomeChannelsEnabled = channels.some(isTrue);

            const handleSetAllChannels = (value: boolean) => {
              formApi.setValue('email', value);
              formApi.setValue('sms', value);
              formApi.setValue('toastNotification', value);
            };

            return (
              <VStack spacing={6}>
                <Show when={!canUserEdit}>
                  <Alert
                    variant="warning"
                    message={
                      notification?.obligation === 'system'
                        ? i18n.t('page.settings.notifications.obligationWarningSystem')
                        : i18n.t('page.settings.notifications.obligationWarningTenant')
                    }
                  />
                </Show>
                <Show when={isSettingsForced && canUserEdit}>
                  <Alert
                    variant="warning"
                    message={i18n.t('page.settings.notifications.tenantSettingsIsForced')}
                  />
                </Show>

                <Text size="small" color="secondary">
                  {i18n.t('page.settings.notifications.detailLabel')}
                </Text>

                <Checkbox
                  value={areSomeChannelsEnabled}
                  onChange={handleSetAllChannels}
                  label={i18n.t('page.settings.notifications.enabled')}
                  data-testid={testIds.myProfile.notificationDetail('enabled')}
                  isDisabled={isFormReadOnly || formApi.formState.isSubmitting}
                />

                <Separator spacing={0} />

                <Heading size={4}>{i18n.t('page.settings.notifications.channels')}</Heading>

                <DataStatus isEmpty={!areAnyChannelsEditable}>
                  <Show when={isTrue(allowedChannels.sms)}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="sms"
                      label={i18n.t('page.settings.notifications.sendToSms')}
                      data-testid={testIds.myProfile.notificationDetail('sendToSms')}
                      isDisabled={isFormReadOnly}
                    />
                  </Show>
                  <Show when={isTrue(allowedChannels.email)}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="email"
                      label={i18n.t('page.settings.notifications.sendToEmail')}
                      data-testid={testIds.myProfile.notificationDetail('sendToEmail')}
                      isDisabled={isFormReadOnly}
                    />
                  </Show>

                  <Show when={isTrue(allowedChannels.toastNotification)}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="toastNotification"
                      label={i18n.t('page.settings.notifications.sendToApp')}
                      data-testid={testIds.myProfile.notificationDetail('sendToApp')}
                      isDisabled={isFormReadOnly || isFalsy(allowedChannels.toastNotification)}
                    />
                  </Show>
                </DataStatus>
                <CustomFieldsNotificationSettings
                  obligation={notification?.obligation}
                  data-testid={testIds.myProfile.notificationDetail('additionalSettings')}
                  notificationSettings={notificationSettings}
                  formApi={formApi}
                />

                <Space vertical={14} />
                <MyProfileFooter
                  actions={[
                    {
                      type: 'button',
                      variant: 'secondary',
                      title: i18n.t('general.actions.discardChanges'),
                      onClick: () => formApi.reset(defaultValues),
                      'data-testid': testIds.myProfile.notificationDetail('discard'),
                    },
                    {
                      type: 'form-button',
                      control,
                      title: i18n.t('general.actions.saveChanges'),
                      buttonType: 'submit',
                      isDisabled: isFormReadOnly,
                      'data-testid': testIds.myProfile.notificationDetail('save'),
                    },
                  ]}
                />
              </VStack>
            );
          }}
        </Form>
      </Box>
    </MyProfileTemplate>
  );
}
