import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  ButtonGroup,
  Card,
  Choice,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  NumberInput,
  RadioItem,
  Separator,
  TextInput,
  isCurrency,
} from 'platform/components';
import {Box, Grid, HStack, Icon, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {Pattern, match} from 'ts-pattern';
import {ValidationError, object} from 'yup';

import {useCallback} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {defaultTo, isNil, isNotNil, join, mergeAll, not} from 'ramda';
import {isFalse, isFalsy} from 'ramda-adjunct';

import {
  useGetDiscountGroupsQuery,
  useGetEmployeeMechanicsQuery,
  useGetOrderItemForMaterialQuery,
  useGetServiceOrderQuery,
  useGetServiceOrderVariantQuery,
  useGetWarehousesQuery,
  usePatchOrderItemForMaterialMutation,
} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {
  TestIdProps,
  parseDate,
  precisionCalculation,
  suffixTestId,
  yupNumber,
  yupString,
} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {DEFAULT_CURRENCY} from '../../constants/currency';
import {useGetVatRatesOptions} from '../../hooks/useGetVatRatesOptions';
import {getDecimalFromPercentage} from '../../utils/getDecimalFromPercentage';
import {getPercentageFromDecimal} from '../../utils/getPercentageFromDecimal';
import {handleApiError} from '../../utils/handleApiError';
import {ItemPrice} from '../ItemPrice/ItemPrice';
import {EditMaterialItemFormType} from './types/EditMaterialItemFormType';

interface EditMaterialItemProps extends TestIdProps {
  itemId: string;
  serviceCaseId: string;
  serviceOrderId: string;
  serviceJobId: string;
  onClose: VoidFunction;
  onEdited?: VoidFunction;
}

export const EditMaterialItem = (props: EditMaterialItemProps) => {
  const formatCurrency = useFormatCurrency();

  const {data, isLoading, isFetching, isError} = useGetOrderItemForMaterialQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      serviceJobId: props.serviceJobId,
      serviceItemId: props.itemId,
    },
    {refetchOnMountOrArgChange: true}
  );
  const {data: warehouses, isLoading: isWarehousesLoading} = useGetWarehousesQuery();
  const {data: discountGroups, isLoading: isDiscountGroupsLoading} = useGetDiscountGroupsQuery();
  const {data: order, isLoading: isOrderLoading} = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const {data: orderVariant, isLoading: isOrderVariantLoading} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: order?.serviceOrderVariantId ?? ''},
    {skip: isNil(order?.serviceOrderVariantId)}
  );
  const {data: mechanics, isLoading: isMechanicsLoading} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {
      skip:
        isNil(orderVariant?.authorizationProfileId) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const [patchMaterialItem] = usePatchOrderItemForMaterialMutation();

  const [vatOptions] = useGetVatRatesOptions(true);

  const handleSubmit: FormSubmitHandler<EditMaterialItemFormType> = (formData) =>
    patchMaterialItem({
      serviceCaseId: props.serviceCaseId,
      serviceItemId: props.itemId,
      serviceJobId: props.serviceJobId,
      serviceOrderId: props.serviceOrderId,
      body: {
        ...formData,
        isDiscount: !!formData.isCustomDiscount,
        sellingPriceVat: {
          freeCode: formData.vatFreeCode,
          type: formData.vatType,
        },
        isDoNotApplyDiscount: !!formData.isDoNotApplyDiscount,
        discount: {
          sourceType: formData.discountType,
          source: data?.discount?.availableDiscounts?.find(
            (discount) => discount?.sourceType === formData.discountType
          )?.source,
          rate:
            (formData.discountType === 'manual-discount'
              ? getDecimalFromPercentage(formData.discountRate)
              : data?.discount?.availableDiscounts?.find(
                  (discount) => discount?.sourceType === formData.discountType
                )?.rate) ?? 0,
        },
      },
    })
      .unwrap()
      .then(props.onEdited)
      .then(props.onClose)
      .catch(handleApiError);

  const handleCustomPriceSwitch =
    (formApi: UseFormReturn<EditMaterialItemFormType>) => (value: boolean) => {
      if (value) {
        return;
      }

      formApi.setValue('sellingPricePerUnit', data?.baseSellingPricePerUnit?.amount ?? 0);
      formApi.setValue('vatType', data?.baseSellingPriceVat?.type ?? '');
    };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          articleId: data?.warehouse?.warehouseArticleId,
          manufacturerNumber: data?.number,
          manufacturer: data?.warehouse?.manufacturerId,
        },
      ]),
    [data?.warehouse?.warehouseArticleId, data?.number, data?.warehouse?.manufacturerId]
  );

  const validateQuantity = (
    value: number | null,
    formApi: UseFormReturn<EditMaterialItemFormType>
  ) => {
    const valueToValidate = defaultTo(0, value);

    try {
      formApi.clearErrors('quantity');

      quantitySchema(dispensingUnit).validateSync(valueToValidate);

      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        formApi.setError('quantity', {message: error.errors.join(' ')});
      }

      return false;
    }
  };

  const dispensingUnit = defaultTo(1, data?.warehouse?.dispensingUnit);

  const currency = data?.sellingPricePerUnit?.currency;
  const warehouseOptions = warehouses?.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.id,
  }));
  const mechanicOptions = mechanics?.employees?.map((mechanic) => ({
    label: mechanic?.name,
    value: mechanic?.id,
  }));
  const discountGroup = discountGroups?.find(
    (group) => group.id === data?.warehouse?.discountGroup
  );
  const baseVat = vatOptions.find((vat) => vat.value === data?.baseSellingPriceVat?.type);
  const basePrices = join(', ', [
    `${i18n.t('entity.order.itemPurchasePrice')}: ${formatCurrency(
      data?.baseSellingPricePerUnit?.amount ?? 0,
      data?.baseSellingPricePerUnit?.currency ?? DEFAULT_CURRENCY,
      2
    )}`,
    `${i18n.t('general.labels.vat')}: ${baseVat?.fieldLabel}`,
  ]);

  const defaultValues: Partial<EditMaterialItemFormType> = {
    discountRate: getPercentageFromDecimal(
      data?.discount?.availableDiscounts?.find(
        (discount) => discount?.sourceType === 'manual-discount'
      )?.rate
    ),
    discountType:
      data?.discount?.availableDiscounts?.find((discount) => discount?.isSelected)?.sourceType ??
      'manual-discount',
    isCustomDiscount:
      data?.discount?.availableDiscounts?.some((discount) => discount?.isSelected) ?? undefined,
    isCustomPrice: data?.isCustomPrice ?? undefined,
    isUnitPriceWithVat: data?.isUnitPriceWithVat ?? undefined,
    mechanic: data?.assignMechanic ?? undefined,
    quantity: data?.quantity?.amount ?? undefined,
    sellingPricePerUnit: data?.sellingPricePerUnit?.amount ?? undefined,
    vatFreeCode: data?.sellingPriceVat?.freeCode ?? undefined,
    vatType: data?.sellingPriceVat?.type ?? undefined,
    isDoNotApplyDiscount: data?.isDoNotApplyDiscount ?? undefined,
    neededAt: isNotNil(data?.warehouse?.neededAt) ? parseDate(data?.warehouse?.neededAt) : null,
  };

  return (
    <DataStatus
      isLoading={isLoading || isFetching || isDiscountGroupsLoading || isWarehousesLoading}
      isError={isError}
      minHeight={87}
    >
      <Form<EditMaterialItemFormType>
        defaultValues={defaultValues}
        schema={formSchema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <Card variant="inlineGrey">
              <VStack spacing={4}>
                <Grid spacing={4} columns={2}>
                  <Choice
                    value={data?.warehouse?.warehouseId ?? null}
                    options={warehouseOptions ?? []}
                    label={i18n.t('entity.warehouse.labels.warehouse')}
                    isDisabled
                    menuInPortal
                    data-testid={suffixTestId('warehouse', props)}
                  />
                  <TextInput
                    value={data?.warehouse?.storageLocation ?? null}
                    label={i18n.t('entity.warehouse.labels.storageLocation')}
                    isDisabled
                    data-testid={suffixTestId('storageLocation', props)}
                  />
                  <TextInput
                    value={discountGroup?.name ?? null}
                    label={i18n.t('entity.workshopCustomerGroup.labels.rabatGroup')}
                    isDisabled
                    data-testid={suffixTestId('discountGroup', props)}
                  />
                  <FormField
                    control={control}
                    label={i18n.t('entity.warehouse.labels.neededAt')}
                    name="neededAt"
                    type="date"
                    data-testid={suffixTestId('neededAt', props)}
                  />
                </Grid>
                <Separator spacing={0} />
                <Grid spacing={4} columns={4}>
                  <FormField
                    control={control}
                    name="quantity"
                    type="number"
                    label={i18n.t('entity.warehouse.labels.quantity')}
                    step={dispensingUnit}
                    isStepperVisible
                    shouldRoundStepsByDifference
                    onChange={(value) => validateQuantity(value, formApi)}
                    decimalPlaces={4}
                    isDisabled={!data?.isQuantityEditingAllowed}
                    data-testid={suffixTestId('quantity', props)}
                  />
                  <FormField
                    control={control}
                    type="currency"
                    name="sellingPricePerUnit"
                    currency={isCurrency(currency) ? currency : undefined}
                    label={i18n.t('entity.order.itemPurchasePrice')}
                    isDisabled={
                      !formApi.watch('isCustomPrice') ||
                      isFalse(data?.sellingPricePerUnit?.isEditingAllowed)
                    }
                    data-testid={suffixTestId('sellingPricePerUnit', props)}
                  />
                  <FormField
                    control={control}
                    type="choice"
                    name="vatType"
                    label={i18n.t('general.labels.vat')}
                    options={vatOptions}
                    isDisabled={
                      !formApi.watch('isCustomPrice') ||
                      isFalse(data?.sellingPriceVat?.isEditingAllowed)
                    }
                    isNotClearable
                    menuInPortal
                    data-testid={suffixTestId('vat', props)}
                  />
                  <FormField
                    control={control}
                    type="choice"
                    name="mechanic"
                    options={mechanicOptions}
                    isLoading={isOrderLoading || isOrderVariantLoading || isMechanicsLoading}
                    label={i18n.t('entity.order.labels.mechanic')}
                    menuInPortal
                    data-testid={suffixTestId('mechanic', props)}
                  />
                </Grid>
                <Show when={formApi.watch('isCustomPrice')}>
                  <HStack spacing={2}>
                    <Icon size={4} value="action/info" color="palettes.neutral.300.100" />
                    <Text size="xSmall" color="tertiary">
                      {`${i18n.t('entity.orderItem.labels.customPriceDescription')} ${basePrices}.`}
                    </Text>
                  </HStack>
                </Show>
                <Box maxWidth="fit-content">
                  <FormField
                    control={control}
                    type="switch"
                    name="isCustomPrice"
                    label={i18n.t('entity.orderItem.labels.customPrice')}
                    onChange={handleCustomPriceSwitch(formApi)}
                    isDisabled={isFalse(data?.isCustomPriceEditingAllowed)}
                    data-testid={suffixTestId('isCustomPrice', props)}
                  />
                </Box>
                <HStack justify="space-between" align="center">
                  <FormField
                    control={control}
                    type="switch"
                    name="isCustomDiscount"
                    label={i18n.t('general.labels.discount')}
                    isDisabled={isFalsy(data?.discount?.isEditingAllowed)}
                    data-testid={suffixTestId('discount', props)}
                  />
                  <FormField
                    control={control}
                    name="isDoNotApplyDiscount"
                    type="checkbox"
                    isDisabled={isFalsy(data?.isDoNotApplyDiscountEditable)}
                    label={i18n.t('general.labels.doNotApplyDiscount')}
                    data-testid={suffixTestId('doNotApplyDiscount', props)}
                  />
                </HStack>
                <Show
                  when={
                    formApi.watch('isCustomDiscount') && not(data?.discount?.isDoNotApplyDiscount)
                  }
                >
                  <Grid columns={4}>
                    <Show
                      when={data?.discount?.availableDiscounts?.find(
                        (discount) => discount?.sourceType === 'customer-contract'
                      )}
                    >
                      <RadioItem
                        value={formApi.watch('discountType') === 'customer-contract'}
                        onChange={() => formApi.setValue('discountType', 'customer-contract')}
                        data-testid={suffixTestId('customer-contract', props)}
                      >
                        {i18n.t('entity.customerContract.labels.customerContract')}
                      </RadioItem>
                      <NumberInput
                        isDisabled
                        value={
                          getPercentageFromDecimal(
                            data?.discount?.availableDiscounts?.find(
                              (discount) => discount?.sourceType === 'customer-contract'
                            )?.rate ?? 0
                          ) ?? 0
                        }
                        suffix="%"
                        data-testid={suffixTestId('customer-contract', props)}
                      />
                    </Show>
                    <Show
                      when={data?.discount?.availableDiscounts?.find(
                        (discount) => discount?.sourceType === 'custom-discounts'
                      )}
                    >
                      <RadioItem
                        value={formApi.watch('discountType') === 'custom-discounts'}
                        onChange={() => formApi.setValue('discountType', 'custom-discounts')}
                        data-testid={suffixTestId('custom-discounts', props)}
                      >
                        {i18n.t('entity.orderItem.labels.customDiscount')}
                      </RadioItem>
                      <NumberInput
                        isDisabled
                        value={
                          getPercentageFromDecimal(
                            data?.discount?.availableDiscounts?.find(
                              (discount) => discount?.sourceType === 'custom-discounts'
                            )?.rate ?? 0
                          ) ?? 0
                        }
                        suffix="%"
                        data-testid={suffixTestId('custom-discounts', props)}
                      />
                    </Show>
                    <Show
                      when={data?.discount?.availableDiscounts?.find(
                        (discount) => discount?.sourceType === 'manual-discount'
                      )}
                    >
                      <RadioItem
                        value={formApi.watch('discountType') === 'manual-discount'}
                        onChange={() => formApi.setValue('discountType', 'manual-discount')}
                      >
                        {i18n.t('entity.orderItem.labels.manualDiscount')}
                      </RadioItem>
                      <FormField
                        control={control}
                        type="number"
                        name="discountRate"
                        decimalPlaces={2}
                        suffix="%"
                        maxStepperValue={100}
                        minStepperValue={0}
                        isDisabled={formApi.watch('discountType') !== 'manual-discount'}
                        data-testid={suffixTestId('discount', props)}
                      />
                    </Show>
                  </Grid>
                </Show>
                <Separator spacing={0} />
                <ItemPrice
                  itemId={props.itemId}
                  serviceCaseId={props.serviceCaseId}
                  serviceOrderId={props.serviceOrderId}
                  serviceJobId={props.serviceJobId}
                  amount={formApi.watch('quantity')}
                  purchasePricePerUnit={data?.purchasePricePerUnit?.amount}
                  purchaseCurrency={data?.purchasePricePerUnit?.currency}
                  sellingPricePerUnit={formApi.watch('sellingPricePerUnit')}
                  sellingCurrency={data?.sellingPricePerUnit?.currency}
                  vatType={formApi.watch('vatType')}
                  dispensingUnit={dispensingUnit}
                  formApi={formApi}
                  isEditMaterialItem
                  discountRate={match([
                    formApi.watch('isCustomDiscount'),
                    formApi.watch('discountType'),
                  ])
                    .with([false, Pattern.any], () => 0)
                    .with([true, 'manual-discount'], () =>
                      getDecimalFromPercentage(formApi.watch('discountRate'))
                    )
                    .with([true, Pattern.any], () => {
                      const discountRate = data?.discount?.availableDiscounts?.find(
                        (discount) => discount?.sourceType === formApi.watch('discountType')
                      )?.rate;

                      return isNil(discountRate) ? 0 : discountRate;
                    })
                    .otherwise(() => 0)}
                  data-testid={suffixTestId('itemPrice', props)}
                />
              </VStack>
            </Card>
            <DataGrid
              gridCode="warehouse-availability"
              autoHeight={true}
              queryModifier={queryModifier}
              data-testid={suffixTestId('availability-dg', props)}
            />
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.labels.discard')}
                onClick={props.onClose}
                variant="secondary"
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.labels.saveChanges')}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
};

const quantitySchema = (dispensingUnit: number) =>
  yupNumber
    .min(0.0001, (params) => i18n.t('general.errors.number.greaterOrEqual', {min: params.min}))
    .test(
      'isQuantityDivisibleByDispensingUnit',
      `${i18n.t('entity.warehouse.labels.quantityMustBeMultipleOfDispensingUnit')} (${dispensingUnit})`,
      (value) => precisionCalculation.modulo(defaultTo(0, value), dispensingUnit) === 0
    );

const formSchema = object({
  quantity: yupNumber.required(),
  sellingPricePerUnit: yupNumber.required(),
  vatType: yupString.required(),
  mechanic: yupString.required(),
  discountRate: yupNumber
    .min(0)
    .max(100)
    .when(['discountType', 'isCustomDiscount'], {
      is: (discountType: string, isCustomDiscount: boolean) =>
        discountType === 'manual-discount' && isCustomDiscount === true,
      then: yupNumber.required(),
      otherwise: yupNumber,
    }),
});
